import { faMemoPad } from '@fortawesome/pro-light-svg-icons'
import {
  faBellOn,
  faCheckCircle,
  faClipboardList,
  faCog,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faBellOn as faBellOnSolid,
  faChevronDown,
  faChevronLeft,
  faClipboardList as faClipboardListSolid,
  faCog as faCogSolid,
  faMemoPad as faMemoPadSolid,
  faPlus,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover } from '@headlessui/react'
import { LoaderFunctionArgs, redirect } from '@remix-run/node'
import { Link, NavLink, Outlet, useLoaderData, useParams, useSearchParams } from '@remix-run/react'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useRef, useState } from 'react'

import { Organization, pluralize } from '@rooquest/common'

import { ClientOnly } from '~/components/ClientOnly'
import { routes } from '~/config/app-routes'
import AdminContextMenu from '~/features/admin-context-menu/components/AdminContextMenu'
import Guide from '~/features/guides/components/Guide'
import { guideRegistry } from '~/features/guides/registry'
import ModalAddOrganization from '~/features/organizations/components/ModalAddOrganization'
import { useAppSidebarStore } from '~/hooks/useAppSidebar'
import useBrandingImage from '~/hooks/useBrandingImage'
import useCurrentOrganization from '~/hooks/useCurrentOrganization'
import useCurrentUser from '~/hooks/useCurrentUser'
import { protectLoader } from '~/server/session.server'

export { ErrorBoundary } from '~/components/RouteBoundaries'

export let loader = async ({ request }: LoaderFunctionArgs) => {
  const { currentUser, memberships } = await protectLoader(request)

  if (!memberships.length) {
    throw redirect(routes.onboarding)
  }

  return {
    $currentUser: currentUser,
    $memberships: memberships,
    organizations: memberships.map((membership) => membership.organization),
  }
}

export default function Component() {
  const params = useParams()
  const { organizations } = useLoaderData<typeof loader>()
  const organization = useCurrentOrganization()

  // sidebar
  const sidebarState = useAppSidebarStore((store) => store.sidebarState)
  const { toggleSidebar, openSidebar } = useAppSidebarStore((store) => store.actions)

  // admin context menu
  const adminContextMenuTriggerRef = useRef<any>(null)

  const [modalAddOrganizationOpen, setModalAddOrganizationOpen] = useState(false)

  const navLinks = [
    {
      label: 'Projects',
      route: routes.projects(params.orgSlug!),
      icon: faClipboardList,
      iconSolid: faClipboardListSolid,
    },
    {
      label: 'Templates',
      route: routes.projectTemplates(params.orgSlug!),
      icon: faMemoPad,
      iconSolid: faMemoPadSolid,
    },
    {
      label: 'Activity',
      route: routes.organizationActivity(params.orgSlug!),
      icon: faBellOn,
      iconSolid: faBellOnSolid,
    },
    {
      label: 'Settings',
      route: routes.organizationSettings(params.orgSlug!),
      icon: faCog,
      iconSolid: faCogSolid,
    },
  ]

  const appLogo = useBrandingImage('senderMenuLogo')
  const appIcon = useBrandingImage('senderMenuIcon')

  return (
    <div className="flex">
      <ClientOnly>
        <AnimatePresence>
          <Guide guide={guideRegistry} pov="sender" />
        </AnimatePresence>
      </ClientOnly>

      {/* sidebar */}
      <motion.div
        initial={false}
        animate={{ width: sidebarState === 'open' ? 230 : 56 }}
        className="w-[230px] h-screen sticky top-0 hidden md:block z-[103]"
        style={{
          background: `linear-gradient(135deg, ${organization.theme.gradientStart}, ${organization.theme.gradientEnd})`,
        }}
      >
        <AdminContextMenu triggerRef={adminContextMenuTriggerRef} />

        {/* panel collapse button */}
        <div className="absolute -right-2 top-4 z-40">
          <button
            onClick={toggleSidebar}
            className="text-white text-[8px] h-4 w-4 rounded-full flex items-center justify-center hover:scale-125 transition-transform"
            style={{ background: organization.theme.gradientEnd }}
          >
            <motion.span initial={false} animate={{ rotate: sidebarState === 'closed' ? 180 : 0 }}>
              <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
            </motion.span>
          </button>
        </div>

        {/* top navigation */}
        <Link to={navLinks[0].route}>
          {sidebarState === 'open' ?
            <img
              alt={organization.name}
              src={appLogo}
              className="m-auto object-contain my-10 hover:scale-105 transition-transform h-[36px] max-w-[150px]"
              ref={adminContextMenuTriggerRef}
            />
          : <img
              alt={organization.name}
              src={appIcon}
              className="h-[34px] m-auto my-10 hover:scale-105 transition-transform"
              ref={adminContextMenuTriggerRef}
            />
          }
        </Link>

        {/* organization selector */}
        <Popover className={cn('relative', sidebarState === 'open' ? 'px-3' : 'px-1')}>
          {({ open }) => (
            <>
              <Popover.Button
                as={motion.button}
                whileTap={{ scale: 0.98 }}
                aria-label="Select organization"
                initial={{ borderBottomRightRadius: 24, borderBottomLeftRadius: 24 }}
                animate={{
                  borderBottomRightRadius: open ? 0 : 24,
                  borderBottomLeftRadius: open ? 0 : 24,
                }}
                transition={{ duration: 0.15 }}
                style={{
                  borderRadius: 24,
                  background: 'rgba(0,0,0,0.3)',
                }}
                className="hover:opacity-90 text-white flex justify-center w-full text-left items-center h-12 px-3 py-3 shadow-xl transition-opacity outline-none"
                onClick={(e: any) => {
                  if (sidebarState === 'closed') {
                    e.preventDefault()
                    openSidebar()
                  }
                }}
              >
                {/* organization icon */}
                <span className="h-6 w-6">
                  {organization.senderMenuIcon ?
                    <img src={appIcon} alt={organization.name} />
                  : <div
                      className="flex items-center justify-center rounded-full uppercase"
                      style={{ background: organization.theme.primaryColor }}
                    >
                      {organization.name.slice(0, 1)}
                    </div>
                  }
                </span>

                {sidebarState === 'open' && (
                  <span className="flex-1 truncate ml-3 block">{organization.name}</span>
                )}

                {/* dropdown icon */}
                {sidebarState === 'open' && (
                  <motion.span
                    animate={{ rotate: open ? 180 : 0 }}
                    className="flex items-center justify-center"
                  >
                    <FontAwesomeIcon icon={faChevronDown} fixedWidth className="text-xs" />
                  </motion.span>
                )}
              </Popover.Button>

              <AnimatePresence initial={false}>
                {open && (
                  <Popover.Panel
                    static
                    as={motion.div}
                    initial={{ y: -10, scaleY: 0.8, height: '98%', transformOrigin: 'top' }}
                    animate={{ y: 0, scaleY: 1, height: 'fit-content' }}
                    exit={{ scaleY: 0.8, opacity: 0, height: '98%' }}
                    transition={{
                      type: 'spring',
                      stiffness: 300,
                      damping: 20,
                      duration: 0.2,
                      bounce: 1,
                    }}
                    className="bg-white rounded-b-[12px] max-h-fit absolute w-[calc(100%-24px)] shadow-lg overflow-hidden"
                  >
                    <ul className="overflow-auto max-h-[300px]">
                      {organizations.map((org) => (
                        <li key={`organization-dropdown-${org.slug}`}>
                          <Popover.Button
                            as={Link}
                            to={routes.organizationDashboard(org.slug)}
                            className={cn(
                              'py-3 px-3 border-t flex items-center text-app-gray-dark text-sm transition-colors',
                              params.orgSlug === org.slug ?
                                'bg-green-100 hover:bg-green-200/80'
                              : 'hover:bg-gray-100'
                            )}
                          >
                            <OrgIcon organization={org} />
                            <span className="ml-3 flex-1">{org.name}</span>
                          </Popover.Button>
                        </li>
                      ))}
                    </ul>

                    <Popover.Button
                      onClick={() => setModalAddOrganizationOpen(true)}
                      className="py-3 px-3.5 border-t w-full flex items-center text-app-gray-dark text-sm hover:bg-gray-100 transition-colors"
                    >
                      <FontAwesomeIcon icon={faPlus} className="text-app-blue-base" fixedWidth />
                      <span className="ml-3">Add Organization</span>
                    </Popover.Button>
                  </Popover.Panel>
                )}
              </AnimatePresence>
            </>
          )}
        </Popover>

        <ModalAddOrganization
          isOpen={modalAddOrganizationOpen}
          onClose={() => setModalAddOrganizationOpen(false)}
        />

        {/* main navigation */}
        <nav className="mt-7" aria-label="global">
          <ul>
            {navLinks.map((link) => (
              <li key={`sidebar-link-${link.route}`}>
                <NavLink
                  to={link.route}
                  end={link.route === routes.dashboard}
                  className={({ isActive }) =>
                    cn(
                      'text-white w-full py-3 transition-colors h-[48px] group',
                      `hover:bg-black hover:bg-opacity-30 hover:text-white`,
                      isActive ? 'font-medium' : 'font-light',
                      sidebarState === 'open' ? 'block px-7' : (
                        'flex items-center justify-center mx-auto'
                      )
                    )
                  }
                  title={link.label}
                >
                  {({ isActive }) => (
                    <div className="flex justify-center items-center group-hover:text-[#F2F9FF]">
                      <FontAwesomeIcon icon={isActive ? link.iconSolid : link.icon} fixedWidth />
                      {sidebarState === 'open' && <span className="flex-1 ml-4">{link.label}</span>}
                    </div>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </motion.div>

      {/* main content */}
      <main className="flex-1">
        <BannerNewOrganization />
        <BannerInviteAccepted />
        <BannerOpenInvites />

        <Outlet />
      </main>
    </div>
  )
}

function OrgIcon(props: { organization: Organization }) {
  const orgIcon = useBrandingImage('senderMenuIcon', props.organization)

  return (
    <div
      className="flex items-center justify-center text-white h-6 w-6 rounded-full p-[3px]"
      style={{ background: props.organization.theme.primaryColor }}
    >
      {props.organization.senderMenuIcon ?
        <img src={orgIcon} alt={props.organization.name} />
      : <span className="uppercase">{props.organization.name.slice(0, 1)}</span>}
    </div>
  )
}

function BannerNewOrganization() {
  const [searchParams, setSearchParams] = useSearchParams()

  if (searchParams.get('action') !== 'new-organization') {
    return null
  }

  return (
    <div role="alert" className="py-3 px-6 bg-[#EDF7ED] flex items-start justify-between gap-4">
      <div className="flex items-start gap-3">
        <FontAwesomeIcon icon={faCheckCircle} className="text-[#61A413] relative top-1 text-lg" />
        <p className="font-medium text-[#1E4620]">
          You've created a new organization. To manage your organizations, go to{' '}
          <Link to={routes.settingsProfile} className="underline whitespace-nowrap">
            Settings / Profile.
          </Link>
        </p>
      </div>

      <button
        onClick={() => setSearchParams({}, { replace: true })}
        className="text-[#1E4620] flex items-center justify-center h-6 w-6 hover:bg-[#1E4620]/20 rounded"
      >
        <FontAwesomeIcon icon={faTimes} className="text-lg" />
      </button>
    </div>
  )
}

function BannerInviteAccepted() {
  const [searchParams, setSearchParams] = useSearchParams()

  if (searchParams.get('action') !== 'invite-accepted') {
    return null
  }

  return (
    <div role="alert" className="py-3 px-6 bg-[#EDF7ED] flex items-start justify-between gap-4">
      <div className="flex items-start gap-3">
        <FontAwesomeIcon icon={faCheckCircle} className="text-[#61A413] relative top-1 text-lg" />
        <p className="font-medium text-[#1E4620]">
          You've accepted an invite to this organization. To manage your organizations, go to{' '}
          <Link to={routes.settingsProfile} className="underline whitespace-nowrap">
            Settings / Profile.
          </Link>
        </p>
      </div>

      <button
        onClick={() => setSearchParams({}, { replace: true })}
        className="text-[#1E4620] flex items-center justify-center h-6 w-6 hover:bg-[#1E4620]/20 rounded"
      >
        <FontAwesomeIcon icon={faTimes} className="text-lg" />
      </button>
    </div>
  )
}

export function BannerOpenInvites() {
  const currentUser = useCurrentUser()
  if (!currentUser) throw new Error('No user found')

  if (!currentUser.invites.length) {
    return null
  }

  return (
    <div role="alert" className="py-3 px-6 bg-[#FFF4E5] flex items-center justify-between gap-4">
      <div className="flex items-start gap-3">
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          className="text-[#EF8923] relative top-1 text-lg"
        />
        <p className="font-medium text-[#663C00]">
          You have{' '}
          {pluralize`${currentUser.invites.length} ${['pending organization invite', 'pending organization invitations']}`}
          . Manage them from the{' '}
          <Link to={routes.settingsProfile} className="underline whitespace-nowrap">
            User Settings
          </Link>{' '}
          page.
        </p>
      </div>

      <Link
        to={routes.settingsProfile}
        className="text-[#663C00] px-2 py-1.5 hover:bg-[#663C00]/20 rounded-full leading-none"
      >
        Respond
      </Link>
    </div>
  )
}
